import { template as template_9abeee93e8cb41c2bd71ab4c9bf7e694 } from "@ember/template-compiler";
const FKText = template_9abeee93e8cb41c2bd71ab4c9bf7e694(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
