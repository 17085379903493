import { template as template_692473e66fb841f5b2c0a337838f8057 } from "@ember/template-compiler";
const WelcomeHeader = template_692473e66fb841f5b2c0a337838f8057(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
